.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #222222;
  color: #e4e4e4;
}

a {
  color: #ffd267;
  transition: 1s;
}

a:hover {
  color: #ffffff;
  transition: 1s;
}

.login-center {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skola-button {
  background-color: #333333;
  color: #e4e4e4;
  transition: 1s;
  border-radius: 10px;
  outline: none;
  border: none;
  padding-inline: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.skola-button:hover {
  background-color: #ffd267;
  color: #333333;
  transition: 1s;
}

.skola-button-secondary {
  background-color: #222222;
  color: #e4e4e4;
  transition: 1s;
  border-radius: 10px;
  outline: none;
  border: none;
  padding-inline: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.skola-button-secondary:hover {
  background-color: #ffd267;
  color: #333333;
  transition: 1s;
}

.skola-panel {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.skola-cards {
  margin-top: 20px;
  margin-bottom: 20px;
}

.skola-cards div {
  background-color: #333333;
  color: #e4e4e4;
  border-radius: 10px;
  padding-inline: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.skola-popup {
  background-color: #222222 !important;
  color: #e4e4e4;
  border-radius: 10px;
  text-align: left;
}

.skola-popup div {
  outline: none;
  border: none;
}

.skola-popup div div ul {
  background-color: #333333;
  color: #e4e4e4;
}

.skola-popup div div ul button {
  color: #e4e4e4;
  transition: 1s;
}

.skola-popup div div ul button:hover{
  background-color: #ffd267;
  color: #333333;
  transition: 1s;
}

.skola-form {
  background-color: #333333;
  color: #e4e4e4;
  border: none;
}